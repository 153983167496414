import "./TripStyles.css";
import TripData from "./TripData";
import Loc from "../assets/loc.jpg";
import Van from "../assets/van.jpg";
import Jett from "../assets/jett.jpg";
import Vip from "../assets/vip.jpg";

function Services() {
    return (
        <div className="Trip">
            <h1>Votre expérience, Notre mission</h1>
            <p>
            Chez MobConciergerie, notre engagement dépasse la simple location de véhicules. 
            À Paris, nous nous efforçons de répondre à chacune de vos attentes, même celle qui ne sont pas immédiatement visibles. 
            En mobilisant nos réseaux nous nous assurons que chaque demande soit satisfaite avec précision et soin. 
            Que ce soit pour organiser des expériences exclusive, faciliter vos déplacements, ou répondre à vos besoins particuliers, nous mettons tout en œuvre pour rendre votre séjour à Paris exceptionnel et à la hauteur de vos attentes.
            </p>
            <div className="tripcard">
                <TripData 
                    image={Loc}
                    heading="Roulez avec élégance et confort"
                    text={
                        <>
                            🚗Découvrez notre vaste sélection de véhicules.<br />
                            De la citadine à la berline en passant par les sportives, conduisez le modèle qui correspond à vos besoins. <br />
                            Profitez d’une expérience de conduite haut de gamme et sensationnelle adapté à toute vos envies.
                        </>
                    }
                />

                <TripData 
                    image={Jett}
                    heading="Voyagez en toute exclusivité avec style !  "
                    text={
                        <>
                            🛫Décollez depuis la ville de votre choix partout en Europe avec notre service jets privés.<br />
                            Bénéficiez d’un accueil VIP et d’un confort optimal pour vos voyages.<br /> 
                            Appréciez la discrétion et l’efficacité de nos prestations pour voyager en toute sérénité.
                        </>
                    }
                />

                <TripData 
                    image={Van}
                    heading="Voyagez en groupe avec tout le confort et le luxe sans compromis."
                    text={
                        <>
                            🚙 Idéal tant pour les professionnels que les particuliers, vous avez la possibilité de louer un van avec ou sans chauffeur partout en France. <br />
                            Bénéficiez d’un confort optimal et d’une flexibilité totale pour vos déplacements en groupe.<br />
                            Où que vous alliez, MobConciergerie vous garantit des trajets spacieux et agréables.
                        </>
                    }
                />

                <TripData 
                    image={Vip}
                    heading="Un accueil sur mesure"
                    text={
                        <>
                            🧳Profitez d’un service personnalisé et propriétaire incluant un accueil, une assistance, la gestion de vos bagages et un accompagnement jusqu’à l’embarquement en priorité. <br />
                            Laissez nous gérer les formalités administratives pour vous et voyagez en toute tranquillité.
                        </>
                    }
                />
            </div>
        </div>
    );
}

export default Services;
