import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ServicetImg from "../assets/11.jpg";
import Footer from "../components/Footer";
import Paris from "../components/Paris.js";



function Service (){
    return(
        <>
        
        <Navbar />
        <Hero 
        cName="hero-mid"
        HeroImg={ServicetImg}
        title="Nos Services sur Paris"

        btnClass="hide"
        />
        <Paris />
        <Footer />
        </>
    )
}


export default Service;