import React, { Component } from "react";
import "./NavbarStyles.css";
import { Link } from "react-router-dom";
import { MenuItems } from "./MenuItems";

class Navbar extends Component {
    state = {
        clicked: false,
        servicesDropdown: false, // Pour gérer le sous-menu "Nos Services"
    };

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked });
    };

    // Fonction pour afficher le sous-menu au clic
    toggleServicesDropdown = () => {
        this.setState({ servicesDropdown: !this.state.servicesDropdown });
    };

    handleOutsideClick = (event) => {
        // Ferme le sous-menu si on clique en dehors de celui-ci
        if (this.node && !this.node.contains(event.target)) {
            this.setState({ servicesDropdown: false });
        }
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    render() {
        return (
            <nav className="NavbarItems" ref={node => { this.node = node; }}>
                <h1 className="navbar-logo" href="/home">
                    MobConciergerie
                </h1>

                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>

                <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                    {MenuItems.map((item, index) => {
                        if (item.submenu) {
                            return (
                                <li
                                    key={index}
                                    className="dropdown"
                                    onMouseEnter={this.toggleServicesDropdown}
                                    onMouseLeave={() => this.setState({ servicesDropdown: false })}
                                >
                                    <Link className={item.cName} to={item.url} onClick={this.toggleServicesDropdown}>
                                        {item.title}
                                        {item.icon && <i className={item.icon}></i>} {/* Icône ajoutée ici */}
                                    </Link>
                                    <ul className={this.state.servicesDropdown ? "dropdown-menu show" : "dropdown-menu"}>
                                        {item.submenu.map((subItem, subIndex) => (
                                            <li key={subIndex}>
                                                <Link to={subItem.url} onClick={() => this.setState({ servicesDropdown: false })}>
                                                    {subItem.icon && <i className={subItem.icon}></i>}
                                                    {subItem.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            );
                        } else {
                            return (
                                <li key={index}>
                                    <Link className={item.cName} to={item.url}>
                                        {item.icon && <i className={item.icon}></i>} {/* Icône ajoutée ici */}
                                        {item.title}
                                    </Link>
                                </li>
                            );
                        }
                    })}
                </ul>
            </nav>
        );
    }
}

export default Navbar;
