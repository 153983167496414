import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Penth from "../assets/press.jpg";
import Footer from "../components/Footer";
import Articles from "../components/Articles";

function Actus(){
    return(
        <>
        <Navbar />
        <Hero 
            cName="hero-mid"
            HeroImg={Penth}
            title={<><span>Dans les Médias :</span><br /><span>Nos Dernières Actualités</span></>} 
            btnClass="hide"
/>
        <Articles />
        <Footer />
        </>
    )
}


export default Actus;