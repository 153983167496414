import React, { useState } from 'react';
import './Articles.css';
import Trip2 from "../assets/ArticleMob.png"; // Remplacez par votre image d'article de presse

const Articles = () => {
    const [isFullScreen, setIsFullScreen] = useState(false); // État pour le plein écran

    // Fonction pour basculer entre le plein écran et l'affichage normal
    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    return (
        <div className='articles-container'>
            <h2 className='title'> Restez informé </h2>
            <h3>Les événements récents qui ont capté l'attention</h3>

            <div className='container about_container'>
                <div className='about_me-image' onClick={toggleFullScreen}>
                    <img src={Trip2} alt='Article Image' />
                                {/* Nouvelle section pour la flèche et le message */}
            <div className='arrow-container'>
                <p>N'hésitez pas à cliquer pour lire l'article 👆🏼</p>
            </div>
                </div>
                <div className='gratitude-section'>
                    <p>
                        Nous tenons à exprimer notre sincère gratitude envers notre fidèle clientèle et nos partenaires qui ont contribué à la réussite de Mobconciergerie. Grâce à votre confiance et votre soutien, nous sommes ravis d'élargir notre offre de services à Paris. Nous sommes déterminés à continuer de fournir une expérience de conciergerie de luxe exceptionnelle, tant à Dubaï qu'à Paris. Votre satisfaction est notre priorité, et nous avons hâte de vous accompagner dans vos projets futurs.
                    </p>
                </div>
            </div>

            {isFullScreen && (
                <div className='full-screen-container' onClick={toggleFullScreen}>
                    <img src={Trip2} alt='Full Screen Article Image' className='full-screen-image' />
                    <button className='close-button' onClick={toggleFullScreen}>✖</button>
                </div>
            )}


        </div>
    );
}

export default Articles;
