export const MenuItems = [
    {
        title: "🏡 Accueil",
        url: "/",
        cName: "nav-links",
        icon: "",
    },
    {
        title: "🌐À propos",
        url: "/about",
        cName: "nav-links",
        icon: "",
    },
    { 
        title: "💻 Nos Services",  // Titre avec l'icône
        url: "#",
        cName: "nav-links dropdown",
        icon: "fa-solid fa-caret-down", // Icône de la flèche vers le bas
        submenu: [
            {
                title: "🇦🇪Dubaï",
                url: "/service",
                icon: "",
            },
            {
                title: "🇫🇷Paris",
                url: "/paris",
                icon: "fa-solid fa-eiffel-tower",
            },
        ],
    },
    {
        title: "📰 Notre Actualité",
        url: "/actus",
        cName: "nav-links",
        icon: "",
    },
    {
        title: "🔗 Nos réseaux",
        url: "/links",
        cName: "nav-links",
        icon: "",
    },
    {
        title: "📞 Contact",
        url: "/contact",
        cName: "nav-links",
        icon: "",
    },
];
